import {
  graphql,
  PageProps
} from "gatsby";
import React from "react";

import Text from "src/components/text";
import Layout from "src/components/layout";
import breadCrumbSchema, { IBreadCrumbSchema } from "src/components/html-schema-snippets/breadcrumb-schema";
import HelmetWrapper from "src/components/helmet-wrapper";
import Header from "src/components/header";
import Link from "src/components/link";
import Icon from "src/components/icon";
import PageTop from "src/components/page-top";
import {
  FullWidthColumnWrapper,
  SmallColumnWidthWrapper
} from "src/components/confines-wrappers";
import styled, { css } from "src/theme/styled";
import {
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper
} from "src/theme/theme-helper";
import Wysiwyg from "src/components/wysiwyg";
import { JoinCard } from "src/components/careers/JoinCard";
import {
  IVacancy,
  VacanciesList
} from "src/components/careers/VacanciesList";
import Footer from "src/components/footer";

interface VacancyPageProps extends PageProps {
  data: {
    nodeCareersVacancy: IVacancy;
    allNodeCareersVacancy: {
      nodes: IVacancy[];
    };
  };
}

const VacancyPage: React.FC<VacancyPageProps> = ({ data }) => {
  const vacancy = React.useMemo(() => data.nodeCareersVacancy, [ data.nodeCareersVacancy ]);
  const allVacancies = React.useMemo(() => data.allNodeCareersVacancy.nodes, [ data.allNodeCareersVacancy.nodes ]);

  const breadcrumbsSchemaData = breadCrumbSchema([
    {
      name: vacancy.title,
      path: vacancy.gatsbyPath
    }
  ]);

  const schemaJson: IBreadCrumbSchema[] = [ breadcrumbsSchemaData ];

  return (
    <Layout>
      <HelmetWrapper
        title={vacancy.title}
        updatedAt={vacancy.changed}
        canonicalPath={vacancy.gatsbyPath}
      >
        <script type="application/ld+json">
          {JSON.stringify(schemaJson)}
        </script>
      </HelmetWrapper>

      <header
        role="banner"
        id="hero"
      >
        <Header
          initialLogo
          initialTheme="light"
        />

        <PageTop>
          <HeaderWrapper>
            <BackButton href="/careers">
              <Icon
                icon="arrowLeft"
                color="grey"
              />

              <BackButtonText>
                Back
              </BackButtonText>
            </BackButton>

            <SmallColumnWidthWrapper>
              <RoleText>
                Role
              </RoleText>

              <TitleText>
                {vacancy.title}
              </TitleText>
            </SmallColumnWidthWrapper>
          </HeaderWrapper>
        </PageTop>
      </header>

      <main
        id="main-content"
        role="main"
      >
        {vacancy.field_job_advert_summary?.processed && (
          <SmallColumnWidthWrapper>
            <Wysiwyg
              type="formatted"
              content={vacancy.field_job_advert_summary.processed}
            />
          </SmallColumnWidthWrapper>
        )}

        <FullWidthColumnWrapper>
          <JoinCard />
        </FullWidthColumnWrapper>

        <SmallColumnWidthWrapper>
          <Wysiwyg
            type="formatted"
            content={vacancy.field_job_advert_copy.processed}
          />
        </SmallColumnWidthWrapper>

        <FullWidthColumnWrapper>
          <JoinCard />
        </FullWidthColumnWrapper>

        <VacanciesList vacancies={allVacancies} />
      </main>

      <footer role="contentinfo">
        <Footer />
      </footer>

    </Layout>
  );
};

export default VacancyPage;

const HeaderWrapper = styled(FullWidthColumnWrapper)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const BackButton = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;

  i {
    margin-right: 1.5rem;
  }

  margin-top: 3rem;
  @media (min-width: 768px) {
    margin-top: 0;
  }
`;

const BackButtonText = styled(Text).attrs({
  fontWeight: "bold",
  letterSpacing: "medium",
  fontColor: "grey"
})`
  text-transform: uppercase;
`;

const RoleText = styled(Text).attrs({
  fontWeight: "bold",
  letterSpacing: "medium",
  fontColor: "secondary",
  fontSize: "subhead_2"
})`
  margin-top: 3rem;
  @media (min-width: 768px) {
    margin-top: 6rem;
  }
  text-transform: uppercase;
`;

const TitleText = styled.h1`
${({ theme }) => css`
  transition: 0.2s ease-in-out;
  display: block;
  padding-top: 0;
  margin-top: 2rem;
  margin-bottom: 3rem;
  font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_2"
  })};
  font-weight: ${fontWeightHelper({
    theme,
    variant: "heavy"
  })};
  line-height: ${fontLineHeightHelper({
    theme,
    variant: "default"
  })};

@media (min-width: 768px) {
  max-width: 66rem;
  font-size: ${fontSizeHelper({
    theme,
    variant: "display_2"
  })};
  line-height: ${fontLineHeightHelper({
    theme,
    variant: "small"
  })};
}

@media (min-width: 960px) {
  max-width: 76rem;
  font-size: ${fontSizeHelper({
    theme,
    variant: "display_3"
  })};
}

@media (min-width: 1024px) {
  max-width: 84rem;
  font-size: ${fontSizeHelper({
    theme,
    variant: "display_4"
  })};
}
`}
`;

export const query = graphql`
  query vacancyQuery ($id: String) {
    nodeCareersVacancy(id: { eq: $id }) {
      id
      created
      changed
      title
      gatsbyPath(filePath: "/careers/{NodeCareersVacancy.title}")
      field_job_advert_summary {
        processed
      }
      field_job_advert_copy {
        processed
      }
    }
    allNodeCareersVacancy {
      nodes {
        id
        created
        title
        gatsbyPath(filePath: "/careers/{NodeCareersVacancy.title}")
      }
    }
  }
`;