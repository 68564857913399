import React from "react";

import styled, { css } from "src/theme/styled";
import Link from "src/components/link";
import Text from "src/components/text";
import generator from "src/components/gradients/generator";
import Life1ImgSrc from "src/assets/images/careers/life-1.png";
import Life2ImgSrc from "src/assets/images/careers/life-2.png";
import Life3ImgSrc from "src/assets/images/careers/life-3.png";
import {
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper
} from "src/theme/theme-helper";

export const JoinCard: React.FC = () => (
  <JoinCardContainer>
    <HalfWidthColumn>
      <JoinTaglineText>
        Why join our team
      </JoinTaglineText>

      <JoinTitleText>
        Approachable, flexible, inspiring individuals.
      </JoinTitleText>

      <CareersLink href="/careers">
        <CareersLinkText>
          Life at Gravitywell
        </CareersLinkText>
      </CareersLink>
    </HalfWidthColumn>

    <JoinImageColumn>
      <Life1Img
        src={Life1ImgSrc}
        alt="Luke talking"
      />

      <Life2Img
        src={Life2ImgSrc}
        alt="Hugo working"
      />
    </JoinImageColumn>

    <JoinImageColumn>
      <Life3Img
        src={Life3ImgSrc}
        alt="Mobile testing"
      />
    </JoinImageColumn>
  </JoinCardContainer>
);

const JoinCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  background: ${generator({
    gradient: "lightPinkPurple",
    angle: -38
  })};
  box-shadow:
    0 3.4px 2.7px rgba(0, 0, 0, 0.006),
    0 8.7px 6.9px rgba(0, 0, 0, 0.009),
    0 17.7px 14.2px rgba(0, 0, 0, 0.011),
    0 36.5px 29.2px rgba(0, 0, 0, 0.014),
    0 100px 80px rgba(0, 0, 0, 0.02)
  ;
  padding: 4rem 2rem;
  margin: 3rem 0;

  @media (min-width: 768px) {
    padding: 5rem 6rem;
    margin: 4rem 0;
  }

  @media (min-width: 960px) {
    padding: 10rem 12rem;
    margin: 6rem 0;
  }
`;

const JoinImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  @media (min-width: 768px) {
    width: auto;
    margin-left: 0;
    &:last-of-type {
      margin-left: 1rem;
      margin-right: auto;
    }
  }
  @media (min-width: 1280px) {
    margin-left: auto;
    &:last-of-type {
      margin-left: 2rem;
      margin-right: 0;
    }
  }
  
`;

const HalfWidthColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;

  @media (min-width: 1280px) {
    flex-basis: 40%;
    margin-bottom: 0;
  }
`;

const JoinTaglineText = styled(Text).attrs({
  fontColor: "secondary",
  letterSpacing: "medium",
  fontWeight: "bold",
  fontSize: "small_3"
})`
  text-transform: uppercase;

@media (min-width: 768px) {
  font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "body_1"
  })};
}
`;

const JoinTitleText = styled(Text).attrs({
  fontSize: "display_1",
  fontWeight: "heavy",
  fontColor: "primary"
})`
${({ theme }) => css`

  margin-top: 2rem;
  margin-bottom: 3rem;

  font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_2"
  })};
  font-weight: ${fontWeightHelper({
    theme,
    variant: "heavy"
  })};
  line-height: ${fontLineHeightHelper({
    theme,
    variant: "default"
  })};

@media (min-width: 768px) {
  margin-top: 4rem;
  margin-bottom: 6rem;

  font-size: ${fontSizeHelper({
    theme,
    variant: "display_2"
  })};
  line-height: ${fontLineHeightHelper({
    theme,
    variant: "small"
  })};
}

@media (min-width: 960px) {
  font-size: ${fontSizeHelper({
    theme,
    variant: "display_3"
  })};
}

@media (min-width: 1024px) {
  font-size: ${fontSizeHelper({
    theme,
    variant: "display_4"
  })};
}
`}`;

const CareersLink = styled(Link)`
  align-self: flex-start;
`;

const CareersLinkText = styled(Text).attrs({
  fontWeight: "heavy",
  fontSize: "subhead_2",
  fontColor: "secondary"
})``;

const Image = styled.img`
  object-fit: cover;
  border: 3px solid white;
`;

const Life1Img = styled(Image)`
  margin-bottom: 2rem;
  width: 100%;
  height: auto;
  @media (min-width: 768px) {
    margin-bottom: 1rem;
    width: 28rem;
    height: 15.75rem;
  }
  @media (min-width: 1280px) {
    margin-bottom: 2rem;
  }
`;

const Life2Img = styled(Image)`
  margin-bottom: 2rem;
  width: 100%;
  height: auto;
  @media (min-width: 768px) {
    margin-bottom: 0;
    width: 19rem;
    height: 14.25rem;
  }
`;

const Life3Img = styled(Image)`
  margin-bottom: 2rem;
  width: 100%;
  height: auto;
  @media (min-width: 768px) {
    margin-bottom: 0;
    width: 31rem;
    height: 31rem;
  }
  @media (min-width: 1280px) {
    width: 32rem;
    height: 32rem;
  }
`;